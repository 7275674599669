let currentScript = document.currentScript;
// ---- Set some defaults for currentScript.dataset
let c = Object.assign({
    fliplineApi: 'https://app.flipline.in/rest',
    fliplineBrowser: undefined,
    fliplineDomain: undefined,
    fliplineDsn: undefined,
    fliplineFlushInterval: '1000',
    fliplineMeta: undefined,
    fliplinePrevious: undefined,
    fliplineRecord: undefined,
    fliplineReplay: undefined,
    fliplineRr: 0,
    fliplineTab: undefined,
    fliplineFlushInProgress: 'no',
  },
  currentScript.dataset);
Object.assign(currentScript.dataset, c);
// ----
export const config = currentScript.dataset;
export const scriptUrl = new URL(document.currentScript.src);
export const tabUrl = new URL(window.location.href);
export const FL = 'Flipline';
