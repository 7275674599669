import { NetworkData, NETWORK_PLUGIN_NAME } from '../record';
import type { eventWithTime } from '../types';
import { EventType } from '../types';

export type OnNetworkData = (data: NetworkData) => void;

export type NetworkReplayOptions = {
  onNetworkData: OnNetworkData;
};

export const getReplayNetworkPlugin: (
  options: NetworkReplayOptions,
) => any = (options) => {
  return {
    handler(event: eventWithTime) {
      if (
        event.type === EventType.Plugin &&
        event.data.plugin === NETWORK_PLUGIN_NAME
      ) {
        const networkData = event.data.payload as NetworkData;
        options.onNetworkData(networkData);
      }
    },
  };
};