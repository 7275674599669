import rrwebPlayer from 'rrweb-player';
import {
  getReplayConsolePlugin
} from 'rrweb';
import 'rrweb-player/dist/style.css';
import {
  config,
  FL
} from './_config.js';
import {
  getReplayNetworkPlugin
} from './network/replay/index.ts';
import {
  getReplayStatsPlugin
} from './stats/replay/index.js';

async function fetchEvents() {
  let response = await fetch(`${config.fliplineApi}/${config.fliplineTab}/r`, {
    redirect: 'follow'
  });
  if (!response.ok) {
    return []
  }
  rrbox.innerHTML = '';
  let data = await response.json();
  return data.events;
}




async function fliplineReplay() {
  var tab = document.querySelector('[data-current-tab-uuid]');
  tab = tab.dataset.currentTabUuid;
  if (tab !== null && tab !== undefined) {
    config.fliplineTab = tab;
  }
  console.log(`${FL} T: ${config.fliplineTab}`);
  if (config.fliplineTab === undefined) {
    console.log("Early exit. No tab found.")
    //return // TODO: Remove this later on after debugging is finished
  }
  var events = await fetchEvents()
  if (events.length === 0) {
    return
  }

  var props = {
    events,
    plugins: [

      getReplayConsolePlugin({
        level: ['info', 'log', 'warn', 'error', "trace"],
        replayLogger: {
          log: (data) => {
            let line = document.createElement('details');
            line.classList.add('bg-base-200', 'px-1', 'py-0', 'overflow-scroll', 'w-full','hide-scrollbar')
            let summary = `
              <summary class="hide-scrollbar">
              ${data.level.slice(0, 3)} | ${data.payload.join(' ')}
              </summary>

              <div class='flex flex-wrap w-full'>${data.trace.join('<br  />')}</div>
              `;
            line.innerHTML = summary
            ConsoleBox.appendChild(line)
            document.querySelectorAll(`[data-debugbar-mirror='Console']`).forEach(el => {
              el.querySelector('[data-debugbar-display]').innerHTML = ConsoleBox.innerHTML
            })

          }
        }
      }),
      getReplayNetworkPlugin({
        onNetworkData: function (data) {
          for (const req of data.requests) {
            let line = document.createElement('details')
            line.classList.add('bg-base-200', 'px-1', 'py-0', 'overflow-scroll', 'w-full','hide-scrollbar')
            // -- network summary
            let method = req.method || 'GET';
            let color = 'badge-info';
            if (req['status'] < 300) {
              color = 'badge-success';
            } else if (req['status'] >= 400) {
              color = 'badge-error';
            }
            method = `<small class="badge ${color} gap-1 badge-xs">${method}</small>`
            let domain = req.url.split('://')[1].split('/')[0];
            let tail = req.url.split('://')[1].split('/', 1)[1] || '';
            let summary = `
              <summary class="hide-scrollbar">
              ${method}\t${domain}\t${tail}
              </summary>

              <div class="whitespace-pre-wrap w-full overflow-x-scroll hide-scrollbar">${JSON.stringify(req)}</div>
              `
            // ---
            line.innerHTML = summary
            NetworkBox.appendChild(line)
            document.querySelectorAll(`[data-debugbar-mirror='Network']`).forEach(el => {
              el.querySelector('[data-debugbar-display]').innerHTML = NetworkBox.innerHTML
            })
          }
        }
      }),
      getReplayStatsPlugin({
        onStatsData: function (data) {
          if (data.currentTime !== undefined) {
            PerformanceBox.querySelector('[data-time]').innerHTML = data.currentTime;
          }
          if (data.performance !== undefined) {
            PerformanceBox.querySelector('[data-performance]').innerHTML = JSON.stringify(data.performance);
          }
          document.querySelectorAll(`[data-debugbar-mirror='Performance']`).forEach(el => {
            el.querySelector('[data-debugbar-display]').innerHTML = PerformanceBox.innerHTML
          })

        }
      })

    ],
  };
  props['width'] = rrbox.offsetWidth;
  props['height'] = rrbox.offsetHeight;
  if (rrbox.dataset.fullscreen === 'True') {
    props['showController'] = false;
    props['maxScale'] = 0;
    // taken from
    // https://www.howtocreate.co.uk/tutorials/javascript/browserwindow
    // https://stackoverflow.com/questions/1038727/how-to-get-browser-width-using-javascript-code
    var myWidth = 0,
      myHeight = 0;
    if (typeof (window.innerWidth) == 'number') {
      //Non-IE
      myWidth = window.innerWidth;
      myHeight = window.innerHeight;
    } else if (document.documentElement && (document.documentElement.clientWidth || document.documentElement.clientHeight)) {
      //IE 6+ in 'standards compliant mode'
      myWidth = document.documentElement.clientWidth;
      myHeight = document.documentElement.clientHeight;
    } else if (document.body && (document.body.clientWidth || document.body.clientHeight)) {
      //IE 4 compatible
      myWidth = document.body.clientWidth;
      myHeight = document.body.clientHeight;
    }
    props['width'] = myWidth;
    props['height'] = myHeight;
  }
  new rrwebPlayer({
    target: rrbox,
    props: props
  });
}

(async () => {
  if (config.fliplineApi === undefined) {
    config.fliplineApi = prodApi;
  }
  console.log(`${FL} API: ${config.fliplineApi}`);
  await fliplineReplay()
})()
