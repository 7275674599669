export const PLUGIN_NAME = 'flipline/stats';


export  const getReplayStatsPlugin = (options) => {
    return {
       handler(event){
         if(event.data.plugin === PLUGIN_NAME){
           options.onStatsData(event.data.payload)
         }
       }
     }
  }
